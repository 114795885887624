import {ReactComponent as Pattern } from '../../assets/pattern.svg'
import { useTranslation } from 'react-i18next'
import {ReactComponent as Icon} from '../../assets/icon.svg'
const Privileges = () => {
    const {t} = useTranslation();
  return (
    <section id="privileges" className="izmr-grid relative">
       
       <div className='relative z-20 col-start-1 col-end-13 izmr-grid'>
       <Pattern className='absolute top-1/2 -translate-y-1/2  hidden md:block'/>
      
       <div className="col-start-1 col-end-13 mt-[100px] mb-20 relative">
            <h2 className="dark:text-white text-black font-bold text-[50px] text-center">{t("privileges.title")}</h2>
        </div>
        <div className="col-start-1 md:col-end-7 col-end-13">
            <div className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 py-[50px] px-[45px] hover:border hover:border-primary relative privileges-bottom-right-corner md:min-h-[485px]  flex flex-col justify-center">
                <h2 className="text-primary font-bold text-[35px]">{t("privileges.marketing")}</h2>
                <p className="dark:text-white text-black opacity-50 text-lg font-medium mt-6">{t("privileges.marketingText")}</p>
              
            </div>
            <div className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 py-[50px] px-[45px]  hover:border hover:border-primary mt-5 privileges-top-right-corner md:min-h-[485px]  flex flex-col justify-center">
                <h2 className="text-primary font-bold text-[35px]">{t("privileges.board")}</h2>
                <p className="dark:text-white text-black opacity-50 text-lg font-medium mt-6">{t("privileges.boardText")}</p>
            </div>
        </div>
        <Icon className='absolute left-1/2  -translate-x-1/2 top-[59%] hidden md:block'/>
        <div className="col-start-1 md:col-start-7 col-end-13">
        <div className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 py-[50px] px-[45px]  hover:border hover:border-primary privileges-bottom-left-corner md:min-h-[485px]  flex flex-col justify-center">
                <h2 className="text-primary font-bold text-[35px]">{t("privileges.nft")}</h2>
                <p className="dark:text-white text-black opacity-50 text-lg font-medium mt-6">{t("privileges.nftText")}</p>
            </div>
            <div className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 py-[50px] px-[45px]  hover:border hover:border-primary mt-5 privileges-top-left-corner md:min-h-[485px] flex flex-col justify-center">
                <h2 className="text-primary font-bold text-[35px]">{t("privileges.blockchain")}</h2>
                <p className="dark:text-white text-black opacity-50 text-lg font-medium mt-6">{t("privileges.blockchainText")}</p>
            </div>

        </div>
       </div>
    </section>
  )
}
export default Privileges