import classNames from "classnames"
import { createElement } from "react"


const Button = ({children, as="button", variant="primary", corner, className , ...props}) => {
  return (
    createElement(as, {
        className:classNames(
            "flex justify-center items-center px-6 py-2 relative font-semibold text-lg hover:scale-105 transition-all duration-300 ease-in-out" ,
            {
                'bg-primary dark:text-white text-black button-shadow': variant === 'primary',
                'bg-transparent border border-primary hover:bg-primary' : variant === 'outline',
                'button-bottom-right': corner === 'bottom-right',
                [className]: className
            },
           
        ),
        ...props
    }, <>{children}</>)
  )
}
export default Button
