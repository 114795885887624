import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    theme:'dark',
    language:'tr',
}

const app = createSlice({
    name:'app',
    initialState,
    reducers:{
        _setLanguage:(state,action)=>{
            state.language = action.payload;
        },
        _setTheme:(state,action)=>{
            state.theme = action.payload;
        }
    }
});


export const { _setLanguage, _setTheme } = app.actions;
export default app.reducer;
