import { useTranslation } from 'react-i18next'
import { ReactComponent as Logo } from "../../assets/footerLogo.svg";
import { ReactComponent as LogoLight } from "../../assets/footerLogoLight.svg";
import { ReactComponent as Logo2 } from "../../assets/Logo2.svg";
import { ReactComponent as Insta } from "../../assets/insta.svg";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import { ReactComponent as Mail } from "../../assets/mail.svg";
import { ReactComponent as Telegram } from "../../assets/telegram.svg";
import { ReactComponent as InstaLight } from "../../assets/instaLight.svg";
import { ReactComponent as TwitterLight } from "../../assets/twitterLight.svg";
import { ReactComponent as MailLight } from "../../assets/mailLight.svg";
import { ReactComponent as TelegramLight } from "../../assets/telegramLight.svg";
import { ReactComponent as Linkedin } from "../../assets/linkedin.svg";
import { ReactComponent as LinkedinLight } from "../../assets/linkedinLight.svg";
import { ReactComponent as Pattern } from "../../assets/pattern.svg";
import {useTheme} from "../../stores/app/hooks"
import { Link } from 'react-router-dom';
const Contact = () => {
  const {t} = useTranslation();
  const theme = useTheme()

  return (
    <section id="contact" className="izmr-grid relative overflow-x-hidden md:overflow-x-visible">
      <Pattern className="absolute top-1/2 -translate-y-1/2 -left-[860px] -z-[1] "/>
      <Pattern className="absolute top-1/2 -translate-y-1/2  -right-[860px] -z-[1] "/>
      <h2 className="mt-[100px] mb-20 font-bold text-[50px] dark:text-white text-black text-center col-start-1 col-end-13">
      {t("contact.title")}
      </h2>
      <div className="col-start-1 md:col-end-7 col-end-13">
        {
          theme === "dark" ? <Logo/> : <LogoLight/>
        }
        <p className="text-lg dark:text-white text-black opacity-50 font-semibold mt-[50px]">
        {t("contact.desc")}
        </p>
          <p className="text-lg dark:text-white text-black opacity-50 font-semibold mt-[10px]">
              {t("contact.mailAddress")}
          </p>
          <a href="mailto:marketing@izmirtoken.io" className="text-lg dark:text-white text-black opacity-90 font-semibold">
              marketing@izmirtoken.io
          </a>
        <div className="mt-[50px] flex space-x-4">
          {theme === "dark" ? <a className='hover:scale-105 transition-all' href="https://www.instagram.com/izmirtoken/"><Insta /></a> : <a className='hover:scale-105 transition-all' href='https://www.instagram.com/izmirtoken/'><InstaLight/></a> }
          {theme === "dark" ? <a className='hover:scale-105 transition-all' href="https://twitter.com/izmirtoken"><Twitter/></a> : <a className='hover:scale-105 transition-all' href="https://twitter.com/izmirtoken"><TwitterLight/></a> }
          {theme === "dark" ? <a className='hover:scale-105 transition-all' href="mailto:support@izmirtoken.com"><Mail /></a> : <a className='hover:scale-105 transition-all' href="https://medium.com/@izmirtoken"><MailLight/></a>}
          {theme === "dark" ? <a className='hover:scale-105 transition-all' href="https://t.me/izmirtoken"><Telegram/></a> : <a className='hover:scale-105 transition-all' href="https://t.me/izmirtoken"><TelegramLight/></a>}
          {theme === "dark" ? <a className='hover:scale-105 transition-all' href="https://www.linkedin.com/company/i%CC%87zmir-token/"><Linkedin/></a> : <a className='hover:scale-105 transition-all' href="https://www.linkedin.com/company/i%CC%87zmir-token/"><LinkedinLight/></a>}
        </div>
      </div>
      <div className="md:col-start-7 col-start-1 col-end-13 flex flex-col md:flex-row items-end">
        <div className='glow'>
       {/*
        <Button  className="dark:text-white text-black w-full md:w-auto  button-top-left-corner-outline">
        {t("general.liveSupport")}
        </Button>
       */}
        </div>
        <Logo2 className="hidden md:block" />
      </div>
      <div className="col-start-1 col-end-13 mt-[221px]">
        <p className="dark:text-white text-black text-center">
        {t("general.copyright")}
        </p>
      </div>
      <div className="col-start-1 col-end-13 mb-[50px]">
        <Link to="/privacy" className="dark:text-white block text-black text-center">
        {t("general.privacyPolicy")}
        </Link>
      </div>
    </section>
  );
};
export default Contact;


