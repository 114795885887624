export const MENU = [
    {
        path:"/",
        dom:true,
        title:"header.nav.home"
    },
    {
        path:"#about",
        dom:false,
        title:"header.nav.about"
    },
    {
        path:"#privileges",
        dom:false,
        title:"header.nav.privileges"
    },
    {
        path:"#tokenomics",
        dom:false,
        title:"header.nav.tokenomics"
    },
    {
        path:"#roadmap",
        dom:false,
        title:"header.nav.roadmap"
    },
    {
        path:"#contact",
        dom:false,
        title:"header.nav.contact"
    }
]