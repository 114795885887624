import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { MENU } from "../../utils/consts/menu";
import { useTranslation } from 'react-i18next'
import { AiOutlineAlignRight } from "react-icons/ai";

const MobileNav = () => {
  const {t} = useTranslation();
  return (
    <div>
        <Menu as="div" className="relative inline-block text-left z-[99999999]">
    <div>
      <Menu.Button className="inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        <AiOutlineAlignRight
          className="dark:text-white text-black "
          size={30}
        />
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:bg-gray bg-white shadow-lg ">
        <div className="px-1 py-1 space-y-4 ">
          {MENU.map((item, index) => (
            <Menu.Item key={index}>
              <a
                href={item.path}
                className="group flex w-full items-center  dark:text-white text-black cursor-pointer font-semibold text-lg "
              >
                {t(item.title)}
              </a>
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
  </div>
  )
}
export default MobileNav