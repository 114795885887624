import {ReactComponent as Graph} from '../../assets/graph.svg'
import {ReactComponent as GraphEN} from '../../assets/graphen.svg'
import {ReactComponent as Pattern} from '../../assets/pattern.svg'
import {ReactComponent as IzmrIcon} from '../../assets/izmrIcon.svg'
import TokenomiGraph from '../../components/tokenomi'
import { useTranslation } from 'react-i18next'
const Tokenomik = () => {
  const {t,i18n} = useTranslation();
  return (
    <section id="tokenomics" className="izmr-grid relative overflow-x-hidden md:overflow-x-visible">
        <Pattern className="absolute top-1/2 -translate-y-1/2 -left-[860px] "/>
        <Pattern className="absolute top-1/2 -translate-y-1/2  -right-[860px]"/>
        <h2 className="col-start-1 col-end-13 text-center dark:text-white text-black text-[50px] font-bold mt-[100px] md:mb-20">
        {t("tokenomics.title")}
        </h2>
        <div className="col-start-1 md:col-end-8 col-end-13 relative">
        <div className="absolute z-1 bg-primary left-0  opacity-50 blur-[200px] rounded-full max-w-[558px] max-h-[558px] w-full h-full"></div>
            <IzmrIcon className='absolute  w-full block'/>
         {
          i18n.language === "tr" ?  <Graph className='w-full block'/> :   <GraphEN className='w-full block'/>
         }
           
        </div>
        <div className="col-start-1 md:col-start-8 col-end-13">
           
           <TokenomiGraph/> 
            
        </div>
    </section>
  )
}
export default Tokenomik