
import Mockup from '../../assets/mockup.png'
import Button from '../../components/button'

import {AiFillApple} from 'react-icons/ai'
import {BsGooglePlay} from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
const About = () => {
  const {t} = useTranslation();

  return (
    <section id="about" className="izmr-grid">
        <h3 className="font-bold text-5xl text-center dark:text-white text-black col-start-1 col-end-13 mt-32 mb-12">{t("about.title")}</h3>
        <div className="col-start-1 md:col-end-6 col-end-13">
         <img src={Mockup} alt="" className='w-full block' />
        </div>
        <div className="md:col-start-7 col-start-1 col-end-13 space-y-8">
            <p className='text-lg text-gray font-medium'>
            <span className='font-bold text-dark dark:text-white'>İzmir Token, </span>{t("about.desc1")}
            </p>
            <p className='text-lg text-gray font-medium'>
            {t("about.desc2")}
            </p>
            <p className='text-lg text-gray font-medium'>
            {t("about.desc3")}
            </p>
            <h2 className='text-[35px] text-primary italic font-bold text-center'>
            {t("about.download")}
            </h2>

            <div className='flex gap-5'>
           <div className="relative w-full">

                <Button as="a" href="https://apps.apple.com/mm/app/i-zmir-token/id6474134227" className="w-full dark:text-white text-black flex items-center gap-2 button-bottom-right-corner-outline py-4 cursor-pointer"> <AiFillApple size={20}/> App Store</Button>
           </div>
                <div className="relative w-full">

                <Button as="a" href="https://play.google.com/store/apps/details?id=com.izmirtoken&hl=en&gl=US&pli=1" className="w-full dark:text-white text-black flex items-center gap-2 button-bottom-left-corner-outline py-4 cursor-pointer"> <BsGooglePlay size={20}/> Play Store</Button>
                </div>
            </div>
        </div>
    </section>
  )
}
export default About
