import React, { useState, useEffect } from 'react';
import {AiOutlineArrowUp,AiOutlineArrowDown} from 'react-icons/ai'
const ScrollButton = () => {

  const [atTop, setAtTop] = useState(true);
  const [atBottom, setAtBottom] = useState(false);

  const checkScrollTop = () => {
    setAtTop(window.scrollY === 0);
  };

  const checkScrollBottom = () => {
    setAtBottom(window.innerHeight + window.scrollY >= document.body.offsetHeight);
  };


  useEffect(() => {
    const onScroll = () => {
      checkScrollTop();
      checkScrollBottom();
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const scrollDown = () => {
    window.scrollBy({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  return (
    <button
      onClick={() => { atBottom ? scrollToTop() : scrollDown(); }}
      style={{
        backgroundColor:"#2EBEEF",
        color:'white',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        padding: '10px',
        zIndex: 1000,
      }}
    >
      {atBottom ? <AiOutlineArrowUp size={25}/> : <AiOutlineArrowDown size={25}/>}
    </button>
  );
};

export default ScrollButton;
