import {MENU} from '../../utils/consts/menu'
import { useTranslation } from 'react-i18next'

const Navbar = () => {
  const { t } = useTranslation();
  return (
    <nav className='space-x-5 ml-10'>
        {MENU.map((item, index) => (
            <a className='dark:text-white text-black font-semibold text-lg hover:text-primary dark:hover:text-primary transition-all' key={index} href={item.path}>{t(item.title)}</a>
        ))}
    </nav>
  )
}
export default Navbar