
import Logo from "../../components/logo";
import Navbar from "../../components/navbar";
import Button from "../../components/button";

import ThemeSwitch from "../../components/theme-switch";
import MobileNav from "../../components/mobile-nav";
import LangSwitch from "../../components/lang-switch";
import { Link } from "react-router-dom";
const Header = () => {
 

  return (
    <header className="flex h-[100px] items-center">
      <Logo />
      <div className="hidden md:block">
        <Navbar />
      </div>
      <div className="ml-auto flex items-center gap-5">
       <div className="hidden md:block">
       <Button as={Link} to="https://wallet.izmirtoken.io/login" className="button-bottom-left-corner" >Giriş Yap</Button>
       </div>
        <LangSwitch />
        <ThemeSwitch />
        <div className="block md:hidden">
        <MobileNav />
        </div>
      </div>
     
    </header>
  );
};
export default Header;

