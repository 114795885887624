import { useTranslation } from "react-i18next"

const Privacy = () => {
  const {t} = useTranslation();
  return (
    <section className="izmr-grid">
      <div className="col-start-1 col-end-13">
      <h2 className="mt-[100px] mb-20 font-bold text-[50px] dark:text-white text-black text-center col-start-1 col-end-13">
      {t("privacy.title")}
      </h2>
      </div>
      <div className="col-start-1 col-end-13">
      <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p1")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p2")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p3")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p4")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p5")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p6")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p7")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p8")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p9")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p10")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p11")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p12")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p13")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p14")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p15")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p16")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p17")}
        </p>7
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p18")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p19")}
        </p>
        <p className="font-medium md:text-[20px] dark:text-white text-black opacity-60 mt-8">
          {t("privacy.p20")}
        </p>
      </div>
      </section>
  )
}
export default Privacy