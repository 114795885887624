import {useState} from "react";

import { ReactComponent as Brand } from "../../assets/brand.svg";
//import useCountdown from "../../hooks/useCountDown";
import Button from "../../components/button";
import Lottie from "lottie-react";
import HeroAnim from "../../assets/brand.json";
import { useTranslation } from "react-i18next";
import WhitepaperTR from "../../assets/whitepaper_tr.pdf";
import WhitepaperEN from "../../assets/whitepaper_en.pdf";
import { Link } from "react-router-dom";

const Hero = () => {
  //const timeLeft = useCountdown("2023-10-29T19:23:00");
  const { t,i18n } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);
 
  return (
    <section className="izmr-grid relative">
      <div className="absolute z-1 bg-primary left-0  opacity-50 blur-[200px] rounded-full max-w-[558px] max-h-[558px] w-full h-full"></div>
      <Brand className="col-start-1 col-end-13 relative md:block hidden  inset-0 z-10 w-full " />
      <div className="col-start-1 col-end-13 md:col-end-7 md:col-start-1 relative z-50 dark:text-white text-black md:-mt-10 mt-10 ">
        <h3 className="font-extrabold md:text-[50px] text-[40px] tracking-[-1.3px]  bg-primary inline-block p-2 hero-text-corner">
          {t("hero.title1")}
        </h3>
        <h3 className="font-extrabold md:text-[50px] text-[40px] tracking-[-1.3px] ">
          {t("hero.title2")}
        </h3>
        <p className="font-medium md:text-[22px] dark:text-white text-black opacity-50 mt-8">
          {t("hero.desc")}
        </p>
        <div className="flex gap-5 mt-10 relative">
       
          <Button as={Link} to="https://wallet.izmirtoken.io/register" className="button-bottom-left-corner py-4">{t("general.register")}</Button>
          <Button as="a" href={i18n.language === "tr" ? WhitepaperTR : WhitepaperEN} target="_blank" className="button-bottom-right-corner-outline">{t("general.whitepaper")}</Button>
        </div>
      </div>
      <div className="md:col-start-7 md:col-end-8 hidden md:block">
       <div className="w-full h-full">
       <svg
          width="183"
          height="433"
          viewBox="0 0 183 433"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M183.22 0.303711L93.626 91.2294V338.062L-0.470947 433.482"
            stroke="#2EBEEF"
            stroke-width="2.5"
            stroke-miterlimit="10"
          />
        </svg>
       </div>
      </div>
      <div className="md:col-start-8 col-start-1 col-end-13 relative z-50 mt-10 md:mt-0">
        <Lottie className="w-full" animationData={HeroAnim} />
        <div>
       
    
        <div className="glow">
        <Button as="a" href="https://exchange.bzetmex.com/protrade/izmrtry" target="_blank" className="button-bottom-right-corner-outline py-6 mt-2"><span className="text-2xl">{t("general.buyNow")}</span></Button>
        </div>
     
        </div>
      </div>
    </section>
  );
};
export default Hero;
