export const languages = [
    {
        key:"tr",
        value:"TR"
    },
    {
        key:"en",
        value:"EN"
    }
]

export const getLanguageName = lang => languages.find(l=> l.key === lang)?.value;