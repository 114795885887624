import { Menu } from "@headlessui/react";
import { languages, getLanguageName } from "../../utils/consts/apperance";
import { useTranslation } from "react-i18next";
import { BsChevronDown } from "react-icons/bs";
const LangSwitch = () => {
    const { i18n } = useTranslation();
  return (
    <div>
      {" "}
      <Menu as="nav" className="relative z-[9999999]">
        <Menu.Button className="flex justify-center items-center gap-2  px-6 py-2  button-bottom-right-corner-outline  font-semibold text-lg dark:text-white text-black hover:scale-105 transition-all duration-300 ease-in-out">
          {getLanguageName(i18n.language)} <BsChevronDown />
        </Menu.Button>
        <Menu.Items
          as="div"
          className="absolute top-full w-[97px]  left-0 translate-y-1  font-semibold text-lg button-bottom-right-corner-outline-lang dark:text-white text-black "
        >
          {languages.map(({ key, value }, index) => (
            <Menu.Item key="index">
              {({ active }) => (
                <button
                  onClick={() => i18n.changeLanguage(key)}
                  className={`w-full py-2 bg-dark hover:bg-primary  ${
                    active && " text-white"
                  }`}
                >
                  {value}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
};
export default LangSwitch;
