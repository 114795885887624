import React from "react";
import ScrollButton from "../../components/scrollButton";
import Hero from "../../containers/hero"
import About from "../../containers/about";
import Privileges from "../../containers/privileges";
import Tokenomik from "../../containers/tokenomik";
import RoadMap from "../../containers/roadmap";


const Home = () => {
  

  return (
    <>
      <Hero/>
      <About/>
      <Privileges/>
      <Tokenomik/>
      <RoadMap/>
     
     <ScrollButton/>
    </>
  )
}
export default Home