import { createBrowserRouter } from "react-router-dom";
import Layout from "../layouts"
import Home from "../pages/home"
import Privacy from "../pages/home/privacy";
const routes = createBrowserRouter([
    {
        path:'/',
        element:<Layout/>,
        children:[
            {
                index:true,element:<Home/>
            },
            {
                path:"/privacy",
                element:<Privacy/>
            }
        ]
    }
])

export default routes