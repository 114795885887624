import Header from "../containers/header";
import Contact from "../containers/contact"
import { useEffect } from "react";
import { useTheme } from "../stores/app/hooks";
import {Helmet} from "react-helmet";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
const App = () => {
  const {i18n} = useTranslation();
  const theme = useTheme()

  useEffect(()=>{
    document.body.className = theme;
  },[theme])


  return  (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{i18n.language === "tr" ? "İzmir Token (IZMR) | İzmir'e Ortak Ol!" : "Izmir Token (IZMR) | Become a Partner in Izmir!"}</title>
        <meta
      name="description"
      content={i18n.language === "tr" ? "İzmir Token (IZMR), İzmir'in zengin tarihini ve eşsiz sosyal deneyimlerini blockchain teknolojisini kullanarak hem yerel hem de küresel yatırımcılara sunacak dijital bir yatırım aracıdır." : "Izmir Token (IZMR) is a digital investment tool that will present Izmir's rich history and unique social experiences to both local and global investors using blockchain technology."}
    />
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11385776051"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11385776051');
          `}
        </script>
      </Helmet>
      <div className="w-full bg-primary text-center">
        <p className="text-white font-normal">
          İzmir Token Kontrat Adresi:
          <span className="font-semibold">0xaeb202756bb7784429e282da887650dcd672284a</span> Lütfen diğer ağlarda oluşturulan sahte kontrat adreslerine dikkat edin ⚠️
        </p>
      </div>
    <main className="max-w-[1300px] mx-auto w-[94%]">

    <Header/>
      <Outlet/>
      <Contact/>
    </main>
  </div>
  );
};
export default App;
