
import { BsSun, BsMoon } from "react-icons/bs";
import { setTheme } from "../../stores/app/actions";
import { useTheme } from "../../stores/app/hooks";

const ThemeSwitch = () => {
    
const theme = useTheme();
  return (
    <div>  
        {theme === "light" ? (
        <BsSun onClick={() => setTheme("dark")} size={30} />
      ) : (
        <BsMoon
          onClick={() => setTheme("light")}
          className="text-white"
          size={30}
        />
      )}</div>
  )
}
export default ThemeSwitch