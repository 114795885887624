import {ReactComponent as Izmr} from '../../assets/logo.svg'
import {ReactComponent as Icon} from '../../assets/icon.svg'

const Logo = () => {
  return (
    <div className="logo">
        <a href='https://izmirtoken.io' className='md:block hidden'>
        <Izmr/>
        </a>
       <a href='https://izmirtoken.io' className='block md:hidden'>
       <Icon/>
       </a>
    </div>
  )
}
export default Logo