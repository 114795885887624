import { useTranslation } from 'react-i18next'

const Tokenomi = () => {
  const {t} = useTranslation();
  return (
    <div className="flex flex-col gap-5">
      <div className="flex gap-5">
        <div className="tokenomi-graph w-full tokenomics-top-left-corner">
          <p className="dark:text-white text-black text-lg font-bold text-center py-5 ">
            Izmir Token
          </p>
        </div>
        <div className="tokenomi-graph w-full">
          <p className="dark:text-white text-black text-lg font-bold text-center py-5">
          {t("tokenomics.distribution")}
          </p>
        </div>
        <div className="tokenomi-graph w-full tokenomics-top-right-corner">
          <p className="dark:text-white text-black text-lg font-bold text-center py-5">{t("tokenomics.quantity")}</p>
        </div>
      </div>
      <div className="flex gap-5">
        <div className="bg-primary tokenomi-shadow w-full">
          <p className="dark:text-white text-black text-lg font-bold text-center py-5">
          {t("tokenomics.marketing")}
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">
            %35
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">70.000.000</p>
        </div>
      </div>
      <div className="flex gap-5 opacity-90">
        <div className="bg-primary tokenomi-shadow w-full">
          <p className="dark:text-white text-black text-lg font-bold text-center py-5">
          {t("tokenomics.public")}
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">
            %25
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">50.000.000</p>
        </div>
      </div>
      <div className="flex gap-5 opacity-80">
        <div className="bg-primary tokenomi-shadow w-full">
          <p className="dark:text-white text-black text-lg font-bold text-center py-5">
          {t("tokenomics.partner")}
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">
            %10
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">20.000.000</p>
        </div>
      </div>
      <div className="flex gap-5 opacity-70">
        <div className="bg-primary tokenomi-shadow w-full">
          <p className="dark:text-white text-blacktext-lg font-bold text-center py-5">
          {t("tokenomics.team")}
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">
            %10
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">20.000.000</p>
        </div>
      </div>
      <div className="flex gap-5 opacity-60">
        <div className="bg-primary tokenomi-shadow w-full">
          <p className="dark:text-white text-black text-lg font-bold text-center py-5">
          {t("tokenomics.liquidity")}
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">
            %8
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">16.000.000</p>
        </div>
      </div>
      <div className="flex gap-5 opacity-50">
        <div className="bg-primary tokenomi-shadow w-full">
          <p className="dark:text-white text-black text-lg font-bold text-center py-5">
          {t("tokenomics.ico")}
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">
            %7
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">14.000.000</p>
        </div>
      </div>
      <div className="flex gap-5 opacity-40">
        <div className="bg-primary tokenomi-shadow w-full">
          <p className="dark:text-white text-black text-lg font-bold text-center py-5">
          {t("tokenomics.airdrop")}
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">
            %5
          </p>
        </div>
        <div className="border border-primary w-full">
          <p className="text-primary text-lg font-bold text-center py-5">10.000.000</p>
        </div>
      </div>
    <div className="w-full tokenomi-graph tokenomics-bottom-left-right-corner">
        <p className="dark:text-white text-black text-2xl font-bold text-center py-5">
        {t("tokenomics.total")}:200.000.000
        </p>
    </div>
    </div>
  );
};
export default Tokenomi;
