import { ReactComponent as TimelineIcon } from "../../assets/timelineIcon.svg";
import { ReactComponent as Icon } from "../../assets/timelineicon2.svg";
import { ReactComponent as Pattern } from "../../assets/pattern3.svg";
import { useTranslation } from 'react-i18next'
const RoadMap = () => {
  const {t} = useTranslation();
  return (
    <section id="roadmap" className="izmr-grid relative">

      <h2 className="dark:text-white text-black font-bold text-[50px] mt-[100px] mb-20 col-start-1 col-end-13 text-center">
      {t("roadmap.title")}
      </h2>
      <div className="col-start-1 col-end-13 relative ">
      <Pattern className="absolute z-10 opacity-60  w-full h-full " />

<div className="container">
          <div
            className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">

            <div className="flex flex-row-reverse md:contents ">
              <div
                className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 px-[35px] py-[28px] col-start-1 col-end-5 p-4  my-4 ml-auto shadow-md roadmap-bottom-right-corner"
              >
                <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-primary text-[35px] flex gap-[13px] items-center">
                  <Icon /> 2022
                </div>
              </div>
              <div className="dark:text-white text-black opacity-50 font-medium text-lg">
                - {t("roadmap.one")}
              </div>
              </div>
              <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-[2px] bg-primary pointer-events-none glow"></div>
                </div>
                <div
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -mt-10 rounded-full shadow"
                >
                   <TimelineIcon />
                </div>
              </div>
            </div>

            <div className="flex md:contents">
              <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-[2px] bg-primary pointer-events-none glow"></div>
                </div>
                <div
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -mt-10 rounded-full shadow rotate-180"
                >
                   <TimelineIcon />
                </div>
              </div>
              <div
                className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 px-[35px] py-[28px] col-start-6 col-end-10 p-4  my-4 ml-auto shadow-md roadmap-left-corner"
              >
               <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-primary text-[35px] flex gap-[13px] items-center">
                  <Icon /> 2023 Q1
                </div>
              </div>
              <div className="dark:text-white text-black opacity-50 font-medium text-lg">
                - {t("roadmap.two")}
              </div>
              </div>
            </div>
            <div className="flex flex-row-reverse md:contents">
              <div
                className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 px-[35px] py-[28px] col-start-1 col-end-5 p-4  my-4 ml-auto shadow-md roadmap-right-corner"
              >
                <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-primary text-[35px] flex gap-[13px] items-center">
                  <Icon /> 2023 Q2
                </div>
              </div>
              <div className="dark:text-white text-black opacity-50 font-medium text-lg">
              <p> - {t("roadmap.three.one")}</p>
               <p> - {t("roadmap.three.two")}</p>
               <p> - {t("roadmap.three.three")}</p>
              </div>
              </div>
              <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-[2px] bg-primary pointer-events-none glow"></div>
                </div>
                <div
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -mt-10 rounded-full shadow"
                >
                   <TimelineIcon />
                </div>
              </div>
            </div>
            <div className="flex md:contents">
              <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-[2px] bg-primary pointer-events-none glow"></div>
                </div>
                <div
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -mt-10 rounded-full shadow rotate-180"
                >
                   <TimelineIcon />
                </div>
              </div>
              <div
                className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 px-[35px] py-[28px] col-start-6 col-end-10 p-4  my-4 shadow-md roadmap-left-corner"
              >
               <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-primary text-[35px] flex gap-[13px] items-center">
                  <Icon /> 2023 Q3
                </div>
              </div>
              <div className="dark:text-white w-full text-black opacity-50 font-medium text-lg">
              <p>- {t("roadmap.four.one")}</p>
               <p> - {t("roadmap.four.two")}</p>
               <p> - {t("roadmap.four.three")}</p>
              </div>
              </div>
            </div>

            <div className="flex flex-row-reverse md:contents">
              <div
                className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 px-[35px] py-[28px] col-start-1 col-end-5 p-4  my-4 shadow-md  roadmap-right-corner"
              >
                <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-primary text-[35px] flex gap-[13px] items-center">
                  <Icon /> 2023 Q4
                </div>
              </div>
              <div className="dark:text-white text-black opacity-50 font-medium text-lg">
              <p>- {t("roadmap.five.one")}</p>
               <p> - {t("roadmap.five.two")}</p>
               <p> - {t("roadmap.five.three")}</p>
              </div>
              </div>
              <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-[2px] bg-primary pointer-events-none glow"></div>
                </div>
                <div
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -mt-10 rounded-full shadow "
                >
                   <TimelineIcon />
                </div>
              </div>
            </div>

            <div className="flex md:contents">
              <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-[2px] bg-primary pointer-events-none glow "></div>
                </div>
                <div
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -mt-10 rounded-full shadow rotate-180 "
                >
                   <TimelineIcon />
                </div>
              </div>
              <div
                className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 px-[35px] py-[28px] col-start-6 col-end-10 p-4  my-4  shadow-md  roadmap-left-corner"
              >
               <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-primary text-[35px] flex gap-[13px] items-center">
                  <Icon /> 2024 Q1
                </div>
              </div>
              <div className="dark:text-white text-black opacity-50 font-medium text-lg">
              <p>- {t("roadmap.six.one")}</p>
              <p>- {t("roadmap.six.two")}</p>
              <p>- {t("roadmap.six.three")}</p>
              </div>
              </div>
            </div>
            <div className="flex flex-row-reverse md:contents">
              <div
                className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 px-[35px] py-[28px] col-start-1 col-end-5 p-4  my-4  shadow-md   roadmap-right-corner"
              >
                <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-primary text-[35px] flex gap-[13px] items-center">
                  <Icon /> 2024 Q2
                </div>
              </div>
              <div className="dark:text-white text-black opacity-50 font-medium text-lg">
              <p>- {t("roadmap.seven.one")}</p>
              <p>- {t("roadmap.seven.two")}</p>
              <p>- {t("roadmap.seven.three")}</p>
              <p>- {t("roadmap.seven.four")}</p>
              <p>- {t("roadmap.seven.five")}</p>

              </div>
              </div>
              <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-[2px] bg-primary pointer-events-none glow"></div>
                </div>
                <div
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -mt-10 rounded-full shadow  "
                >
                   <TimelineIcon />
                </div>
              </div>
            </div>

            <div className="flex md:contents">
              <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-[2px] bg-primary pointer-events-none glow opacity-30"></div>
                </div>
                <div
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -mt-10 rounded-full shadow rotate-180 opacity-30"
                >
                   <TimelineIcon />
                </div>
              </div>
              <div
                className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 px-[35px] py-[28px] col-start-6 col-end-10 p-4  my-4  roadmap-left-corner  shadow-md opacity-30"
              >
               <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-primary text-[35px] flex gap-[13px] items-center">
                  <Icon /> 2024 Q3
                </div>
              </div>
              <div className="dark:text-white text-black opacity-50 font-medium text-lg">
              <p>- {t("roadmap.eight.one")}</p>
              <p>- {t("roadmap.eight.two")}</p>
              <p>- {t("roadmap.eight.three")}</p>
              <p>- {t("roadmap.eight.four")}</p>
              </div>
              </div>
            </div>
            <div className="flex flex-row-reverse md:contents ">
              <div
                className="dark:bg-white bg-black bg-opacity-10 dark:bg-opacity-10 px-[35px] py-[28px] col-start-1 col-end-5 p-4  roadmap-right-corner  my-4 shadow-md opacity-30"
              >
                <div className="flex items-center justify-between space-x-2 mb-1">
                <div className="font-bold text-primary text-[35px] flex gap-[13px] items-center">
                  <Icon /> 2024 Q4
                </div>
              </div>
              <div className="dark:text-white text-black opacity-50 font-medium text-lg">
              <p>- {t("roadmap.nine.one")}</p>
              <p>- {t("roadmap.nine.two")}</p>
              <p>- {t("roadmap.nine.three")}</p>
              <p>- {t("roadmap.nine.four")}</p>
              </div>
              </div>
              <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-[2px] bg-primary pointer-events-none glow opacity-30"></div>
                </div>
                <div
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -mt-10 rounded-full shadow opacity-30"
                >
                   <TimelineIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default RoadMap;

